import { FfNgxEndpointRecords } from '@fagforbundet/ngx-components';

export const MembershipApiEndpoints = {
  GET_ACTIVE_MEMBERSHIP: {
    path: '/v1/users/self/active-membership',
  },
  GET_CANCELLATION_EMPLOYERS: {
    path: '/v1/membership-cancellations/employers',
  },
  GET_CANCELLATION_NOTES: {
    path: '/v1/membership-cancellations/:cancellationUuid/notes',
  },
  GET_CANCELLATION_WORKPLACES: {
    path: '/v1/membership-cancellations/workplaces',
  },
  GET_CANCELLATIONS: {
    path: '/v1/membership-cancellations',
  },
  GET_IS_MEMBER: {
    path: '/v1/is-member',
  },
  GET_IS_SHOP_STEWARD: {
    path: '/v1/is-shop-steward',
  },
  GET_SELF: {
    path: '/v1/users/self',
  },
  GET_SHOP_STEWARD_MEMBERS: {
    path: '/v1/users/self/shop-steward-members',
  },
  GET_SHOP_STEWARD_MEMBERS_DUES_REASONS: {
    path: '/v1/users/self/shop-steward-members/_filters/dues-reasons',
  },
  GET_SHOP_STEWARD_MEMBERS_EMPLOYERS: {
    path: '/v1/users/self/shop-steward-members/_filters/employers',
  },
  GET_SHOP_STEWARD_MEMBERS_PROFESSIONS: {
    path: '/v1/users/self/shop-steward-members/_filters/professions',
  },
  GET_SHOP_STEWARD_MEMBERS_UNIONS: {
    path: '/v1/users/self/shop-steward-members/_filters/unions',
  },
  GET_SHOP_STEWARD_MEMBERS_WORKPLACES: {
    path: '/v1/users/self/shop-steward-members/_filters/workplaces',
  },
  POST_CANCELLATION_FANE2_REFRESH: {
    path: '/v1/membership-cancellations/update-status',
  },
  POST_CANCELLATION_NOTE: {
    path: '/v1/membership-cancellations/:cancellationUuid/notes',
  },
  POST_CANCELLATION_TRANSITION: {
    path: '/v1/membership-cancellations/:cancellationUuid/transition',
  },
} satisfies FfNgxEndpointRecords;
